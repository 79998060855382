import axios from 'axios';

export default {
  find: function (id) {
    return axios.get(`/api/exportPotentialServiceItem/find?id=${id}`);
  },
  update: function (id, request) {
    if (id > 0) return axios.put(`/api/exportPotentialServiceItem/update?id=${id}`, request);
    else return axios.post(`/api/exportPotentialServiceItem/create`, request);
  },
  delete: function (id) {
    return axios.delete(`/api/exportPotentialServiceItem/delete?id=${id}`);
  },

  getServiceList: function () {
    return axios.get(`/api/exportPotentialServiceItem/getServiceList`);
  },
};
